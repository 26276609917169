@import '@aurora/shared-client/styles/_variables.pcss';

.lia-main-links-container {
  display: flex;
  flex-grow: 1;
  justify-content: var(--lia-nav-link-justify-content);
  margin-right: 40px;

  @media (--lia-breakpoint-down-md) {
    display: none;
  }
}

.lia-main-links {
  display: flex;
  overflow-x: hidden;
  gap: var(--lia-nav-link-gap);

  &:has([data-menu-open='true']) {
    overflow-x: unset;
  }
}

.lia-more {
  min-width: max-content;
  margin-left: var(--lia-nav-link-gap);

  &:empty {
    display: none;
  }
}

.lia-is-hidden.lia-is-hidden {
  display: none;
}
