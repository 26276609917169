@import '@aurora/shared-client/styles/_variables.pcss';
@import '@aurora/shared-client/styles/mixins/_focus.pcss';

.lia-toggle.lia-toggle {
  display: flex;
  align-items: center;
  column-gap: 5px;
  padding: 8px;
  margin: -8px;
  color: var(--lia-nav-controller-text-color);
  border-radius: var(--lia-nav-controller-border-radius);
  transition: background-color var(--lia-timing-normal);

  &:hover,
  &:focus {
    color: var(--lia-nav-controller-text-hover-color);
    background-color: var(--lia-nav-controller-bg-hover-color);

    .lia-icon {
      fill: var(--lia-nav-controller-icon-hover-color);
    }
  }

  @include default-focus-outline();
}

.lia-icon {
  fill: var(--lia-nav-controller-icon-color);
}

.lia-visible-on-sm {
  display: none;
}

@media (--lia-breakpoint-down-sm) {
  .lia-visible-on-sm {
    display: flex;
  }
  .lia-language-label {
    display: none;
  }
}
