/*
 * mixin used to share styles used on selectable elements in page editor
 * @param level - denotes the heading tag
 */

@mixin heading-defaults($level: h1) {
  font-size: var(--lia-bs-#{$level}-font-size);
  font-weight: var(--lia-#{$level}-font-weight);
  letter-spacing: var(--lia-#{$level}-letter-spacing);
  font-family: var(--lia-bs-headings-font-family);
  line-height: var(--lia-bs-headings-line-height);
}
