.lia-hamburger {
  display: block;

  .lia-burger-part {
    display: block;
    height: 3px;
    width: 24px;
    border-radius: 1px;
    background-color: var(--lia-bs-body-color);
    transition: transform var(--lia-timing-fast), opacity var(--lia-timing-fast);

    &:not(:last-child) {
      margin-bottom: 3px;
    }
  }
  .lia-lettuce {
    transform-origin: bottom left;
  }
  .lia-cheese {
    transform-origin: top left;
  }
  &.lia-is-active {
    .lia-lettuce {
      transform: translateX(2.5px) translateY(-3px) rotate(45deg);
    }
    .lia-cheese {
      transform: translateX(2.5px) translateY(3px) rotate(-45deg);
    }
    .lia-tomato {
      opacity: 0;
    }
  }
}
