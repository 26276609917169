@import '@aurora/shared-client/styles/mixins/_focus.pcss';

.lia-outer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  &.lia-has-unread {
    margin-right: -10px;
  }
  &:hover,
  &:focus {
    text-decoration: none;
    box-shadow: unset;

    .lia-inner {
      background-color: var(--lia-nav-controller-bg-hover-color);
    }
    .lia-icon {
      fill: var(--lia-nav-controller-icon-hover-color);
    }
  }
  &:focus-visible {
    outline: unset;

    .lia-inner {
      @include default-outline();
    }
  }
}

.lia-inner {
  display: flex;
  position: relative;
  padding: 8px;
  margin: -8px;
  transition: background-color var(--lia-timing-normal);
  border-radius: var(--lia-nav-controller-border-radius);
}

.lia-icon {
  fill: var(--lia-nav-controller-icon-color);
  position: relative;
}

.lia-badge {
  position: relative;
  top: -2px;
  left: -4px;
  display: flex;
  align-items: center;
  padding: 0 7px;
  height: 16px;
  font-size: var(--lia-font-size-xs);
  line-height: 1;
  box-shadow: 0 0 0 2px var(--lia-nav-bg-color);
  border-radius: 20px;
  background-color: var(--lia-nav-controller-icon-highlight);
  color: var(--lia-nav-controller-icon-highlight-text);
}
