@import '@aurora/shared-client/styles/_variables.pcss';

.lia-logo {
  max-width: 100%;
  height: var(--lia-nav-brand-logo-height);

  &.lia-loading {
    display: none;
  }

  @media (--lia-breakpoint-down-md) {
    height: auto;
    max-height: var(--lia-nav-brand-logo-height);
  }
}

.lia-khoros-logo {
  width: 94px;
}
