/*
 * mixin used to share styles used on selectable elements in page editor
 * @param isWidget - widgets receive dashed borders and a higher z-index
 */
@mixin editable-selection($isWidget) {
  position: relative;

  /* pseudo element created to set up and prepare borders */
  &:after {
    content: '';
    position: absolute;
    inset: 0;
    @if $isWidget == true {
      border: 1px dashed transparent;
      z-index: 1040;
    } @else {
      border: 1px dashed var(--lia-bs-gray-500);
      z-index: 1030;
    }
    transition: border var(--lia-timing-fast) var(--lia-timing-function);
    pointer-events: none;
  }

  /* styles applied when the element is the current selection */
  &-selected:after {
    @if $isWidget == true {
      border: 1px
        dashed
        hsla(
          var(--lia-page-editor-primary-h),
          var(--lia-page-editor-primary-s),
          var(--lia-page-editor-primary-l),
          0.6
        );
    } @else {
      border: 1px
        solid
        hsla(
          var(--lia-page-editor-primary-h),
          var(--lia-page-editor-primary-s),
          var(--lia-page-editor-primary-l),
          0.6
        );
    }
  }

  /* styles applied when another element is selected. used to hide action controls */
  &-inactive {
    .lia-editor-action-btn {
      transition: opacity var(--lia-timing-normal) var(--lia-timing-function);
      opacity: 0;
    }

    &:hover,
    &:focus-within {
      .lia-editor-action-btn {
        opacity: 1;
      }
    }
  }
}
