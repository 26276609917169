@import '@aurora/shared-client/styles/_variables.pcss';
@import '@aurora/shared-client/styles/mixins/_focus.pcss';

.lia-hamburger {
  display: none;
  margin-right: 20px;

  &.lia-force-hamburger {
    display: flex;
  }

  .lia-hidden-desktop {
    display: none;
  }

  @media (--lia-breakpoint-down-md) {
    display: flex;
    margin-right: 15px;
    position: static;

    .lia-hidden-desktop {
      display: flex;
    }

    .lia-hamburger-menu {
      border: 0;
      border-radius: 0;
      box-shadow: none;
      border-right: 1px solid var(--lia-bs-border-color);
      height: calc(100dvh - var(--lia-d-navbar-height));
      inset: var(--lia-d-navbar-height) 40px 0 0 !important;
      padding-top: 20px;
      padding-bottom: 20px;
      max-width: 400px;
      width: auto;
      overflow-y: scroll;
    }
  }
}

.lia-hamburger-toggle {
  padding: calc(var(--lia-nav-link-py) + 2.5px) var(--lia-nav-link-px);
  margin: -10px;
  background-color: var(--lia-nav-hamburger-bg-color);
  border: var(--lia-nav-hamburger-border);
  border-radius: var(--lia-nav-link-border-radius);

  .lia-hamburger-icon {
    background-color: var(--lia-nav-hamburger-color);
  }
  &:hover {
    background-color: var(--lia-nav-hamburger-bg-hover-color);
    border: var(--lia-nav-hamburger-border-hover);
    outline: none;
    box-shadow: none;

    .lia-hamburger-icon {
      background-color: var(--lia-nav-hamburger-hover-color);
    }
  }
  @include default-focus-outline();
}
