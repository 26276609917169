.lia-wrap {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  .lia-icon {
    transition: transform var(--lia-timing-normal);

    &.lia-is-rotated {
      transform: rotate(90deg);
    }
  }

  .lia-menu {
    display: none;
    flex-direction: column;
    transform-origin: top;
    margin-left: var(--lia-nav-collapse-menu-ml);
    animation: whoosh-in var(--lia-timing-normal) var(--lia-timing-function);

    &.lia-is-open {
      display: flex;
    }
  }

  .lia-icon {
    display: flex;
    align-items: center;
    flex-basis: 100%;
  }

  .lia-divider {
    display: block;
    width: 1px;
    background: var(--lia-nav-collapse-menu-divider-bg);
    opacity: var(--lia-nav-collapse-menu-divider-opacity);
    margin: var(--lia-nav-link-dropdown-py) 10px;
  }
}

@keyframes whoosh-in {
  from {
    opacity: 0;
    transform: scaleY(0.9);
  }
  to {
    opacity: 1;
    transform: scaleY(1);
  }
}
