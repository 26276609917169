/**
 * Default outline styles.
 */
@mixin default-outline() {
  outline: var(--lia-outline-color) solid 3px !important;
  outline-offset: unset;
}

/**
 * Default input-style box-shadow.
 */
@mixin default-input-box-shadow() {
  box-shadow: var(--lia-bs-input-focus-box-shadow), 0 0 0 1px var(--lia-bs-input-focus-border-color);
  outline: unset;
}

/**
 * Default focus-visible outline styles
 */
@mixin default-focus-outline() {
  &:focus {
    box-shadow: unset;
  }
  &:focus-visible {
    @include default-outline();
  }
}

/**
 * Default focus-visible styles for button
 * or button-like elements.
 */
@mixin default-focus-box-shadow() {
  &:focus {
    outline: unset;
    box-shadow: unset;
  }
  &:focus-visible {
    @include default-input-box-shadow();
  }
}
