@import '@aurora/shared-client/styles/_variables.pcss';

/* Styles applied to both editable and non-editable sections */
.lia-section {
  &-spacing {
    padding-top: $lia-section-spacing;
    padding-bottom: $lia-section-spacing;
    &-top-disabled {
      padding-top: 0;
      padding-bottom: $lia-section-spacing;
    }

    &-bottom-disabled {
      padding-top: $lia-section-spacing;
      padding-bottom: 0;
    }

    &-both-disabled {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &-border {
    &-top {
      border-top: 1px solid var(--lia-bs-border-color);
    }

    &-bottom {
      border-bottom: 1px solid var(--lia-bs-border-color);
    }

    &-both {
      border-top: 1px solid var(--lia-bs-border-color);
      border-bottom: 1px solid var(--lia-bs-border-color);
    }
  }

  &-attached-first {
    margin-top: -25px;
    margin-bottom: 25px;
    background-color: var(--lia-bs-white);
  }
}

/* apply 25px vertical spacing between columns as they stack on smaller screens */
@media (--lia-breakpoint-down-md) {
  .lia-column:not(:last-child) {
    margin-bottom: 25px;
  }
}
