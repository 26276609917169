@import '@aurora/shared-client/styles/_variables.pcss';

@media (--lia-breakpoint-up-xl) {
  .lia-fixed-container.lia-fixed-container {
    max-width: var(--lia-container-max-width);
  }
}

@media (--lia-breakpoint-down-xs) {
  .lia-fixed-gutters {
    padding-right: calc(var(--lia-grid-gutter-width-md) * 0.5);
    padding-left: calc(var(--lia-grid-gutter-width-md) * 0.5);
  }
}
