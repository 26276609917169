@import '@aurora/shared-client/styles/mixins/_page-editor.pcss';

.lia-layout-btn {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: 10px auto;
  width: 36px;
  height: 24px;
  border: 0;
  box-shadow: var(--lia-box-shadow-xs);
  background-color: hsla(var(--lia-bs-white-h), var(--lia-bs-white-s), var(--lia-bs-white-l), 0.8);
  border-radius: var(--lia-bs-border-radius-sm);
  z-index: 1020;

  &:hover,
  &:focus {
    border: 0;
    background-color: var(--lia-bs-gray-200);
    box-shadow: var(--lia-box-shadow-xs);
    border-radius: var(--lia-bs-border-radius-sm);
  }
  &:active {
    border: 0 !important;
    background-color: var(--lia-bs-gray-300) !important;
  }
}

.lia-container {
  @include editable-selection(true);
}
