@import '@aurora/shared-client/styles/_variables.pcss';
@import '@aurora/shared-client/styles/mixins/_focus.pcss';

.lia-nav {
  width: 100%;
  position: relative;
  background-color: var(--lia-nav-bg-color);
  padding: var(--lia-nav-pt) 0 var(--lia-nav-pb);
  border-bottom: var(--lia-nav-border-bottom);
  box-shadow: var(--lia-nav-box-shadow);
  transition: background-color 700ms ease;
  z-index: 1020;

  &.lia-nav-fixed {
    position: sticky;
    top: 0;
  }

  &.lia-is-fixed:not(.lia-is-classic) {
    position: fixed;
    width: var(--lia-body-max-width);
    max-width: 100%;
  }

  &.lia-is-editing {
    display: grid;
    grid-template-columns: 55px auto 55px;
    pointer-events: initial;

    .lia-container {
      pointer-events: none;
    }
  }

  &.lia-is-page-builder {
    pointer-events: none;
    z-index: 940;

    &.lia-is-fixed {
      top: var(--lia-d-nav-fixed-top, 0);
      width: var(--lia-d-nav-fixed-width, var(--lia-body-max-width));
    }
  }

  .lia-nav-background {
    transition: opacity 700ms ease;
  }

  &.lia-is-classic.lia-has-transparent {
    .lia-nav-background {
      opacity: 0;
    }
    &:not(.lia-is-fixed) {
      background-color: transparent;
    }
    &.lia-is-fixed .lia-nav-background {
      opacity: 1;
    }
  }

  &:not(.lia-is-classic) {
    &:not(.lia-is-fixed) {
      background-color: var(--lia-d-nav-bg-combined);
    }
    &.lia-is-fixed .lia-nav-background {
      opacity: 1 !important;
    }
  }

  &:hover .lia-edit-controller {
    opacity: 1;
  }
}

.lia-nav-spacer {
  height: 0;

  &.lia-is-fixed {
    height: var(--lia-d-navbar-height);
  }
}

.lia-container {
  display: flex;
  align-items: center;
}

.lia-brand-wrap {
  position: relative;
  margin-right: var(--lia-nav-brand-mr);

  @media (--lia-breakpoint-down-md) {
    margin-right: var(--lia-nav-brand-mr-sm);
  }
  @media (--lia-breakpoint-up-sm) {
    flex-shrink: 0;
  }
}

.lia-brand-logo {
  display: flex;
  align-items: center;
  max-height: var(--lia-nav-brand-logo-height);
}

.lia-skip-content {
  display: block;
  position: absolute;
  left: 0;
  top: calc(var(--lia-d-navbar-height) - var(--lia-nav-pt) - 5px);
  clip-path: circle(0);
  white-space: nowrap;
  user-select: none;
  color: var(--lia-nav-link-color);
  background-color: var(--lia-nav-bg-color);
  box-shadow: var(--lia-nav-link-box-shadow);
  border-radius: var(--lia-bs-dropdown-border-radius);
  font-size: var(--lia-nav-link-font-size);
  font-weight: var(--lia-nav-link-font-weight);
  font-style: var(--lia-nav-link-font-style);
  text-transform: var(--lia-nav-link-text-transform);
  letter-spacing: var(--lia-nav-link-letter-spacing);
  padding: 4px 20px;

  &:hover,
  &:focus {
    color: var(--lia-nav-link-color);
    text-decoration: none;
  }
  &:focus-visible {
    clip-path: none;
  }
}

.lia-section {
  display: flex;
  align-items: center;
}

.lia-controls {
  margin-left: auto;
  gap: 20px;

  @media (--lia-breakpoint-down-md) {
    margin-left: auto;
    gap: 15px;
  }
}

.lia-controller-text-link {
  white-space: nowrap;
  margin-left: calc(-1 * var(--lia-nav-link-px) + 1px);

  &:not(:last-child) {
    margin-right: calc(-1 * var(--lia-nav-link-px) + 1px);
  }
}

.lia-nav-background {
  position: absolute;
  content: '';
  inset: 0 0 0 0;
  z-index: -1;
}

.lia-edit-control-wrap {
  display: flex;
  align-items: center;
  margin: calc(var(--lia-nav-pt) * -1) 0 calc(var(--lia-nav-pb) * -1) 10px;
}

/* Temporary CSS form style */
.lia-form-kill-border.lia-form-kill-border:after {
  display: none;
}
