.lia-avatar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .lia-avatar-img {
    border-radius: var(--lia-avatar-border-radius);
    background-color: var(--lia-bs-gray-400);
    overflow: hidden;
    display: block;
    background-size: contain;
    height: 100%;
    width: 100%;
  }
}
