.lia-navbar-dropdown-anchor {
  color: inherit;

  &:hover,
  &:focus {
    color: var(--lia-nav-link-hover-color);
    text-decoration: none;
    outline: 0;
  }
}

.lia-link-extender {
  position: relative;

  &:after {
    position: absolute;
    display: block;
    content: '';
    width: 100%;
    height: calc(var(--lia-nav-pb) - var(--lia-nav-dropdown-menu-offset));
    top: 100%;
    left: 0;
  }
}
