@import '@aurora/shared-client/styles/_variables.pcss';
@import '@aurora/shared-client/styles/mixins/_typography.pcss';

.lia-section-header {
  margin-bottom: 0;
  @include heading-defaults(h3);

  @media (--lia-breakpoint-down-sm) {
    @include heading-defaults(h5);
  }
}

.lia-section-description {
  display: inline-block;
  padding-top: 5px;
}
