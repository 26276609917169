@import '@aurora/shared-client/styles/_variables.pcss';
@import '@aurora/shared-client/styles/mixins/_focus.pcss';

.lia-toggle.lia-toggle {
  display: flex;
  align-items: center;

  &.lia-has-dot {
    position: relative;
  }
  &:hover,
  &:focus {
    text-decoration: none;
    box-shadow: unset;

    .lia-avatar {
      background-color: var(--lia-nav-controller-bg-hover-color);
    }
    .lia-name {
      color: var(--lia-nav-controller-text-hover-color);
    }
  }
  &:focus-visible {
    outline: unset;

    .lia-avatar {
      @include default-outline();
    }
  }
}

.lia-avatar {
  box-sizing: content-box;
  transition: background-color var(--lia-timing-normal);
  border-radius: var(--lia-avatar-border-radius);
  padding: 5px;
  margin: -5px;
}

.lia-name {
  margin-left: 10px;
  color: var(--lia-nav-controller-text-color);

  @media (--lia-breakpoint-down-md) {
    display: none;
  }
}

.lia-dropdown {
  display: flex;

  @media (--lia-breakpoint-down-md) {
    position: static;
  }
}

.lia-menu {
  right: 0 !important;
  left: auto !important;
  min-width: 200px;
  padding: 15px 0 12px;
  max-height: calc(100dvh - var(--lia-d-navbar-height));
  overflow-y: auto;

  @media (--lia-breakpoint-down-md) {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    border-left: 1px solid var(--lia-bs-border-color);
    border-bottom: 1px solid var(--lia-bs-border-color);
    height: calc(100dvh - var(--lia-d-navbar-height));
    inset: var(--lia-d-navbar-height) 0 0 auto !important;
    padding: 20px 0;
    min-width: 250px;
    max-width: 400px;
    width: auto;
  }
}

.lia-title,
.lia-email,
.lia-rank {
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  overflow-wrap: break-word;
  word-break: break-word;
  cursor: default;
}

.lia-title {
  margin-bottom: 2px;
  font-size: var(--lia-bs-font-size-base);
  font-weight: var(--lia-bs-font-weight-bold);
}

.lia-email {
  margin-bottom: 5px;
  font-size: var(--lia-font-size-xs);

  @media (--lia-breakpoint-down-md) {
    margin-bottom: 10px;
  }
}

.lia-rank {
  margin-bottom: 8px;
}

.lia-item {
  padding: 5px 20px;
  font-size: var(--lia-bs-font-size-sm);
  line-height: 1.428571428;

  @media (--lia-breakpoint-down-md) {
    padding-top: 9px;
    padding-bottom: 9px;
  }
}

.lia-divider {
  margin: 10px 0;

  @media (--lia-breakpoint-down-md) {
    margin: 15px 0;
  }
}

.lia-info-box {
  margin: 10px 20px 20px;
  padding: 15px 20px;
  border-radius: var(--lia-bs-border-radius);
  color: var(--lia-bs-primary);
  background-color: var(--lia-primary-lightest);
  cursor: default;
}

.lia-dot-indicator {
  position: absolute;
  top: -5px;
  right: -5px;
  height: 10px;
  width: 10px;
  box-shadow: 0 0 0 2px var(--lia-nav-bg-color);
  border-radius: var(--lia-border-radius-50);
  background-color: var(--lia-nav-controller-icon-highlight);
}
